import Order from '@/models/order/Order'
import Product from '@/models/product/Product'
import i18n from '@/i18n'
import { formatDateTimeString } from '@/utils/dateUtils'
import TyreHotelWheelSetHistory from '@/models/tyrehotel/TyreHotelWheelSetHistory'

export default class TyreHotelWheelChange {
  public id: number
  public tyreHotelId: number
  public createdAt: Date
  public action: string
  public season: string
  public siteId: number
  public orderId: number
  public receiveStockTransportBundleId: number
  public returnStockTransportBundleId: number
  public executedAt: Date
  public changeCompletedAt: Date
  public lastWheelUpdateAt: Date
  public cancelledAt: Date
  public wheelChangeLocationId: number
  public storageLocationId: number
  public internalComment: string
  public externalComment: string
  public productId: number
  public oldProductId: number
  public receiveTyreHotelWheelSetId: number
  public returnTyreHotelWheelSetId: number
  public receiveTyreHotelWheelSetHistoryId: number
  public returnTyreHotelWheelSetHistoryId: number

  public name: string
  public createdByName: string
  public executedByName: string
  public cancelledByName: string
  public lastWheelUpdateByName: string
  public hasCertificate: boolean
  public isActive: boolean

  public order: Order = null
  public product: Product = null
  public oldProduct: Product = null
  public receiveTyreHotelWheelSetHistory: TyreHotelWheelSetHistory = null

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.tyreHotelId = data.tyreHotelId ? (data.tyreHotelId as number) : null
    this.createdAt = data.createdAt ? (data.createdAt as Date) : null
    this.action = data.action ? (data.action as string) : null
    this.season = data.season ? (data.season as string) : null
    this.siteId = data.siteId ? (data.siteId as number) : null
    this.orderId = data.orderId ? (data.orderId as number) : null
    this.receiveStockTransportBundleId = data.receiveStockTransportBundleId
      ? (data.receiveStockTransportBundleId as number)
      : null
    this.returnStockTransportBundleId = data.returnStockTransportBundleId
      ? (data.returnStockTransportBundleId as number)
      : null
    this.executedAt = data.executedAt ? (data.executedAt as Date) : null
    this.changeCompletedAt = data.changeCompletedAt ? (data.changeCompletedAt as Date) : null
    this.lastWheelUpdateAt = data.lastWheelUpdateAt ? (data.lastWheelUpdateAt as Date) : null
    this.cancelledAt = data.cancelledAt ? (data.cancelledAt as Date) : null
    this.wheelChangeLocationId = data.wheelChangeLocationId ? (data.wheelChangeLocationId as number) : null
    this.storageLocationId = data.storageLocationId ? (data.storageLocationId as number) : null
    this.internalComment = data.internalComment ? (data.internalComment as string) : null
    this.externalComment = data.externalComment ? (data.externalComment as string) : null
    this.productId = data.productId ? (data.productId as number) : null
    this.oldProductId = data.oldProductId ? (data.oldProductId as number) : null
    this.receiveTyreHotelWheelSetId = data.receiveTyreHotelWheelSetId
      ? (data.receiveTyreHotelWheelSetId as number)
      : null
    this.returnTyreHotelWheelSetId = data.returnTyreHotelWheelSetId ? (data.returnTyreHotelWheelSetId as number) : null
    this.receiveTyreHotelWheelSetHistoryId = data.receiveTyreHotelWheelSetHistoryId
      ? (data.receiveTyreHotelWheelSetHistoryId as number)
      : null
    this.returnTyreHotelWheelSetHistoryId = data.returnTyreHotelWheelSetHistoryId
      ? (data.returnTyreHotelWheelSetHistoryId as number)
      : null

    this.name = data.name ? (data.name as string) : null
    this.createdByName = data.createdByName ? (data.createdByName as string) : null
    this.executedByName = data.executedByName ? (data.executedByName as string) : null
    this.cancelledByName = data.cancelledByName ? (data.cancelledByName as string) : null
    this.lastWheelUpdateByName = data.lastWheelUpdateByName ? (data.lastWheelUpdateByName as string) : null
    this.hasCertificate = data.hasCertificate ? (data.hasCertificate as boolean) : null
    this.isActive = data.isActive ? (data.isActive as boolean) : null

    this.product = data.product ? new Product(data.product as Record<string, unknown>) : null
    this.oldProduct = data.oldProduct ? new Product(data.oldProduct as Record<string, unknown>) : null
  }

  public clone(): TyreHotelWheelChange {
    const result = new TyreHotelWheelChange()
    Object.assign(result, this)
    return result
  }

  public get isCancelled(): boolean {
    return !!this.cancelledAt
  }

  public get isTerminated(): boolean {
    return this.action === 'TERMINATED'
  }

  public get openByName(): string {
    return this.createdByName ?? i18n.t('c:tyre-hotel:Unknown user')
  }

  public get finalizeDate(): Date | null {
    return this.executedAt ?? this.cancelledAt ?? null
  }

  public get finalizeByName(): string {
    return this.executedByName ?? this.cancelledByName ?? i18n.t('c:tyre-hotel:Unknown user')
  }

  public get statusMessage(): string {
    const finalizedDateFormatted = formatDateTimeString(this.finalizeDate)

    let value = ''
    if (this.action === 'PRODUCT_CHANGED' && !this.isCancelled) {
      value = i18n.t('c:tyre-hotel:Product changed from "%s" to "%s" by %s at %s')
      return value
        .replace('%s', this.oldProduct.eontyreDescription)
        .replace('%s', this.product.eontyreDescription)
        .replace('%s', this.finalizeByName)
        .replace('%s', finalizedDateFormatted)
    } else {
      if (this.action === 'PRODUCT_CHANGED') {
        value = i18n.t('c:tyre-hotel:Product change cancelled by %s at %s')
      } else if (this.action === 'RECEIVED') {
        if (this.isCancelled) {
          value = i18n.t('c:tyre-hotel:Received cancelled by %s at %s')
        } else {
          value = i18n.t('c:tyre-hotel:Received by %s at %s')
        }
      } else if (this.action === 'RENEWED') {
        if (this.isCancelled) {
          value = i18n.t('c:tyre-hotel:Renewal cancelled by %s at %s')
        } else {
          value = i18n.t('c:tyre-hotel:Renewed by %s at %s')
        }
      } else if (this.action === 'TERMINATED') {
        if (this.isCancelled) {
          value = i18n.t('c:tyre-hotel:Termination cancelled by %s at %s')
        } else {
          value = i18n.t('c:tyre-hotel:Terminated by %s at %s')
        }
      } else if (this.action === 'REACTIVATE') {
        if (this.isCancelled) {
          value = i18n.t('c:tyre-hotel:Reactivation cancelled by %s at %s')
        } else {
          value = i18n.t('c:tyre-hotel:Reactivated by %s at %s')
        }
      }

      if (value !== '') {
        return value.replace('%s', this.finalizeByName).replace('%s', finalizedDateFormatted)
      } else {
        return i18n.t('c:tyre-hotel:Unknown status')
      }
    }
  }

  public static fromArray(data: Array<Record<string, unknown>> = null): Array<TyreHotelWheelChange> {
    const result = []

    data.forEach((value) => {
      result.push(new TyreHotelWheelChange(value))
    })

    return result
  }
}
