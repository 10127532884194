


















































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { formatDateTimeString } from '@/utils/dateUtils'
import DocumentIdentifier from '@/models/document/DocumentIdentifier'
import DocumentDialog from '@/components/document/DocumentDialog.vue'
import TyreHotel from '@/models/tyrehotel/TyreHotel'
import TyreHotelWheelSetHistory from '@/models/tyrehotel/TyreHotelWheelSetHistory'
import Order from '@/models/order/Order'
import BookingDialog from '@/components/booking/BookingDialog.vue'
import { appendSitePrefix, getCurrentSiteId } from '@/utils/routeUtils'
import Booking from '@/models/booking/Booking'

@Component({
  components: { BookingDialog, DocumentDialog },
  methods: { appendSitePrefix, formatDateTimeString },
})
export default class HistoryData extends Vue {
  @Prop({ type: TyreHotel, required: true })
  private tyreHotel: TyreHotel

  private loadedData = false

  private details = []

  private invoices = {}

  private documentDialog = false
  private document = null

  private bookingDialog = false
  private booking = null

  private appendSitePrefix = appendSitePrefix

  private async created() {
    const promises = this.tyreHotel.tyreHotelWheelChanges.map(async(tyreHotelWheelChange) => {
      if (tyreHotelWheelChange.orderId) {
        const response = await this.$axios.get(
          '/v4/site/order/' + tyreHotelWheelChange.orderId + '/invoice?siteId=' + tyreHotelWheelChange.siteId,
        )
        this.$set(this.invoices, tyreHotelWheelChange.orderId, response.data.data)
      }
      if (tyreHotelWheelChange.receiveTyreHotelWheelSetHistoryId) {
        const response = await this.$axios.get(
          '/v4/site/tyre-hotels-v2/wheel-set-history/' + tyreHotelWheelChange.receiveTyreHotelWheelSetHistoryId,
        )
        tyreHotelWheelChange.receiveTyreHotelWheelSetHistory = new TyreHotelWheelSetHistory(response.data.data)
      }
      if (tyreHotelWheelChange.orderId) {
        const orderResponse = await this.$axios.get(
          '/v4/site/order/' + tyreHotelWheelChange.orderId + '?siteId=' + tyreHotelWheelChange.siteId,
        )
        if (orderResponse.data.data) {
          tyreHotelWheelChange.order = new Order(orderResponse.data.data)

          if (tyreHotelWheelChange.order.bookingId) {
            const bookingResponse = await this.$axios.get(
              '/v4/site/calendars/any/bookings/' + tyreHotelWheelChange.order.bookingId,
            )
            if (bookingResponse.data.data) {
              tyreHotelWheelChange.order.booking = new Booking(bookingResponse.data.data)
            }
          }
        }
      }
    })

    await Promise.all(promises)

    this.loadedData = true
  }

  private openInvoiceDocument(invoiceId: number): void {
    this.document = new DocumentIdentifier()
    this.document.type = 'Payment'
    this.document.params = { payment_receipt_id: invoiceId }

    this.documentDialog = true
  }

  private openCertificate(wheelChangeId: number): void {
    this.document = new DocumentIdentifier()
    this.document.type = 'TyreHotelCertificate'
    this.document.params = { tyreHotelWheelChangeId: wheelChangeId }

    this.documentDialog = true
  }

  private toggleDetails(index: number): void {
    this.$set(this.details, index, !this.details[index])
  }

  private openBooking(siteId: number, booking: Booking) {
    if (getCurrentSiteId(siteId)) {
      this.booking = booking
      this.bookingDialog = true
    } else {
      this.$router.push('/' + siteId + '/bookings/' + booking.id)
    }
  }
}
